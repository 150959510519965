export const LSF_TweetsBank = {
    "1440442746712461317": {
        "name": "Ludwig",
        "handle": "LudwigAhgren",
        "text": "this cost $53,000",
        "datetime": "3:28 PM · Sep 21, 2021"
    },
    "1315003049312616448": {
        "name": "Pokimane",
        "handle": "pokimanelol",
        "text": "@BarackObama wanna play among us?",
        "datetime": "11:55 AM · Oct 10, 2020"
    },
    "1431286567423909889": {
        "name": "Sodapoppin",
        "handle": "Sodapoppintv",
        "text": "I had a spider crawl into my pants right before i was gonna put em on last night. This war is getting srs now. Im gonna start putting spider heads on little toothpicks around the house to let em know I'm not fucking around.",
        "datetime": "9:04 AM · Aug 27, 2021"
    },
    "1465155378489053192": {
        "name": "Mizkif",
        "handle": "REALMizkif",
        "text": "ludwig beat me in mario... im such a pussy",
        "datetime": "7:07 PM · Nov 28, 2021"
    },
    "1442011952897683459": {
        "name": "Pokimane",
        "handle": "pokimanelol",
        "text": "i’m so lonely",
        "datetime": "11:23 PM · Sep 25, 2021"
    },
    "1451288036998737925": {
        "name": "Pokimane",
        "handle": "pokimanelol",
        "text": "clout is one helluva drug",
        "datetime": "1:43 PM · Oct 21, 2021"
    },
    "1474863301075034124": {
        "name": "Mizkif",
        "handle": "REALMizkif",
        "text": "I got a fucking toothbrush for Christmas from my mom and she said “you're a millionaire buy the toothpaste”",
        "datetime": "2:03 PM · Dec 25, 2021"
    },
    "1445794302760652806": {
        "name": "Pokimane",
        "handle": "pokimanelol",
        "text": "at least people can't over-exaggerate me “making millions a month off my viewers” anymore 🤪",
        "datetime": "9:53 AM · Oct 6, 2021"
    },
    "1449185569754415105": {
        "name": "Pokimane",
        "handle": "pokimanelol",
        "text": "sprite is god tier soda",
        "datetime": "6:28 PM · Oct 15, 2021"
    },
    "1469116276115001344": {
        "name": "xQc",
        "handle": "xQc",
        "text": "My favorite Bacardi Spiced cocktail is a Spiced & Mule. Making one on stream in a minute :-)",
        "datetime": "5:26 PM · Dec 9, 2021",
        "bait": "Pokimane"
    },
    "1390357634340032517": {
        "name": "Mizkif",
        "handle": "REALMizkif",
        "text": "I HAVE A HERNIA AND I NEED SURGERY",
        "datetime": "10:27 AM · May 6, 2021",
        "bait": "xQc"
    },
    "1443078776238133248": {
        "name": "xQc",
        "handle": "xQc",
        "text": "I JUST KISSED 2 HANDSOME MALES 👄 @LudwigAhgren AND @AustinOnTwitter",
        "datetime": "10:02 PM · Sep 28, 2021"
    },
    "1470126225062608900": {
        "name": "Sodapoppin",
        "handle": "Sodapoppintv",
        "text": "Vtubers are amazing, and ur all a bunch of losers. Ur just jealous i have a QT pie anime GF who appreciates ME. In fact, i have like 8 of them, im subbed & everything, ALL of them love me for who I am and ur jealous. Im living my best life, & u cant tell me wat I CAN and CANT do.",
        "datetime": "12:19 PM · Dec 12, 2021"
    },
    "1402861230390333447": {
        "name": "Ludwig",
        "handle": "LudwigAhgren",
        "text": "I tested a dog collar on myself to see how much they hurt..",
        "datetime": "10:32 PM · Jun 9, 2021"
    },
    "1359779880485982208": {
        "name": "Mizkif",
        "handle": "REALMizkif",
        "text": "Just doing some late night gardening with my new shovel, thanks @Sodapoppintv for being poor!",
        "datetime": "12:22 AM · Feb 11, 2021"
    },
    "1437096879905443847": {
        "name": "Ludwig",
        "handle": "LudwigAhgren",
        "text": "I'm the first person to hangout with mr beast and lose money",
        "datetime": "9:52 AM · Sep 12, 2021"
    },
    "1466963705036374018": {
        "name": "Sykkuno",
        "handle": "Sykkuno",
        "text": "x won me a bear!",
        "datetime": "6:52 PM · Dec 3, 2021"
    },
    "1443673280561352715": {
        "name": "Sykkuno",
        "handle": "Sykkuno",
        "text": "LITERAL SCAMATHON HAPPENING NOW",
        "datetime": "1:25 PM · Sep 30, 2021",
        "bait": "Ludwig"
    },
    "1454517198823772162": {
        "name": "MoistCr1TiKaL",
        "handle": "MoistCr1TiKaL",
        "text": "Roblox has been offline for over 24 hours. God help us",
        "datetime": "11:34 AM · Oct 30, 2021"
    },
    "1452096129156456458": {
        "name": "Sodapoppin",
        "handle": "Sodapoppintv",
        "text": "Ugh all this @Valkyrie blue light  drama is so annoying. It’s taking attention away from MY stream. I had HILARIOUS clips today and NO1 CARES?!!! Ugh what a selfish bitch I can’t believe it I’m livid.",
        "datetime": "7:14 PM · Oct 23, 2021"
    },
    "1386407717774925825": {
        "name": "MoistCr1TiKaL",
        "handle": "MoistCr1TiKaL",
        "text": "Imagine being tall, how embarrassing",
        "datetime": "12:52 PM · Apr 25, 2021",
        "bait": "Ludwig"
    },
    "1310539302078689280": {
        "name": "xQc",
        "handle": "xQc",
        "text": "Everything feels weird and off. Nothing makes sense right now but i'm happy. So... f*ck it I guess?",
        "datetime": "4:18 AM · Sep 28, 2020"
    },
    "1454661299930025984": {
        "name": "Sodapoppin",
        "handle": "Sodapoppintv",
        "text": "Halloween slutfits making me have to jackoff in bathrooms at Halloween house parties #depression #hardboners",
        "datetime": "9:07 PM · Oct 30, 2021"
    },
    "1443961475098415111": {
        "name": "MoistCr1TiKaL",
        "handle": "MoistCr1TiKaL",
        "text": "Nothing will ever beat coming home from school firing up Halo 2 and hopping on Xbox live and getting told my mom was getting fucked by the whole lobby",
        "datetime": "8:30 AM · Oct 1, 2021"
    },
    "1474452707163451393": {
        "name": "Pokimane",
        "handle": "pokimanelol",
        "text": "east coast feels like home 🥺",
        "datetime": "10:51 AM · Dec 24, 2021"
    },
    "1463278028323184641": {
        "name": "Ludwig",
        "handle": "LudwigAhgren",
        "text": "IBAI DESERVED TO WIN STREAMER OF THE YEAR \n \n HE'S ON ANOTHER LEVEL",
        "datetime": "2:47 PM · Nov 23, 2021"
    },
    "1476963750112469001": {
        "name": "HasanAbi",
        "handle": "hasanthehun",
        "text": "2021 felt like 2020 dlc.",
        "datetime": "9:09 AM · Dec 31, 2021"
    },
    "1463636890406440975": {
        "name": "Ludwig",
        "handle": "LudwigAhgren",
        "text": "7 billion people, 14 billion faces",
        "datetime": "2:33 PM · Nov 24, 2021"
    },
    "1343695061868011520": {
        "name": "Mizkif",
        "handle": "REALMizkif",
        "text": "Hi @MsRebeccaBlack can I have permission to play your music on my stream? I know all your songs by heart! ♥️",
        "datetime": "3:07 PM · Dec 28, 2020",
        "bait": "Pokimane"
    },
    "1448044782257836032": {
        "name": "MoistCr1TiKaL",
        "handle": "MoistCr1TiKaL",
        "text": "Just heard Smash Mouth broke up. Don't talk to me today, I need some time",
        "datetime": "2:55 PM · Oct 12, 2021"
    },
    "1439875738010857474": {
        "name": "Sodapoppin",
        "handle": "Sodapoppintv",
        "text": "Just picked my nose and didnt realize i had pepper seeds on my finger and now my nose burns :(",
        "datetime": "1:55 AM · Sep 20, 2021",
        "bait": "Pokimane"
    },
    "1458166337965109255": {
        "name": "MoistCr1TiKaL",
        "handle": "MoistCr1TiKaL",
        "text": "I’m starting to look like an anime character",
        "datetime": "12:15 PM · Nov 9, 2021"
    },
    "1459367218962055174": {
        "name": "Ludwig",
        "handle": "LudwigAhgren",
        "text": "i love politics",
        "datetime": "7:47 PM · Nov 12, 2021",
        "bait": "HasanAbi"
    },
    "1438566021577666561": {
        "name": "Sykkuno",
        "handle": "Sykkuno",
        "text": "unverify me or else . @verified",
        "datetime": "11:10 AM · Sep 16, 2021"
    },
    "1454913087891329027": {
        "name": "Sykkuno",
        "handle": "Sykkuno",
        "text": "hapy haloween !",
        "datetime": "1:48 PM · Oct 31, 2021",
        "bait": "Pokimane"
    },
    "1350187278430138369": {
        "name": "Sykkuno",
        "handle": "Sykkuno",
        "text": "moving to las vegas! ill be back on streams soon!!",
        "datetime": "1:05 PM · Jan 15, 2021"
    },
    "1478911487519301634": {
        "name": "Ludwig",
        "handle": "LudwigAhgren",
        "text": "we raised over $100,000 for @nokidhungry great fkn stream \n :') \n <3",
        "datetime": "6:09 PM · Jan 5, 2022"
    },
    "1457062264247177220": {
        "name": "xQc",
        "handle": "xQc",
        "text": "DOES ANYONE RECOGNIZE ME..? IM KIND OF A BIG DEAL.. HELLO?",
        "datetime": "12:08 PM · Nov 6, 2021"
    },
    "1472332036077727747": {
        "name": "MoistCr1TiKaL",
        "handle": "MoistCr1TiKaL",
        "text": "Still can't believe there's people out there that haven't seen two girls one cup",
        "datetime": "2:24 PM · Dec 18, 2021"
    },
    "1479876824960155650": {
        "name": "HasanAbi",
        "handle": "hasanthehun",
        "text": "please don’t steal my content guys that’d be so terrible. def don’t upload my entire vods onto youtube that would be so horrible.",
        "datetime": "10:05 AM · Jan 8, 2022",
        "bait": "Ludwig"
    },
    "1464250468515811331": {
        "name": "HasanAbi",
        "handle": "hasanthehun",
        "text": "absolutely wrecked my cousins last night in boomerang fu and mario kart. no mercy just because they're 11.",
        "datetime": "7:11 AM · Nov 26, 2021"
    },
    "1326058567858876417": {
        "name": "xQc",
        "handle": "xQc",
        "text": "IF THERE'S ONE SOCIAL SKILL THAT I HAVE MASTERED OVER THE YEARS IT'S BURNING BRIDGES, I'M THE BEST AT IT AND NO ONE COMES CLOSE",
        "datetime": "11:06 PM · Nov 9, 2020"
    },
    "1453433875930120194": {
        "name": "Mizkif",
        "handle": "REALMizkif",
        "text": "Poki",
        "datetime": "11:50 AM · Oct 27, 2021"
    },
    "1466193337539383297": {
        "name": "Ludwig",
        "handle": "LudwigAhgren",
        "text": "I met sykkuno :)",
        "datetime": "3:51 PM · Dec 1, 2021",
        "bait": "xQc"
    },
    "1435413485446443011": {
        "name": "Mizkif",
        "handle": "REALMizkif",
        "text": "Twitter Sucks",
        "datetime": "6:23 PM · Sep 7, 2021"
    },
    "1479514572465737730": {
        "name": "HasanAbi",
        "handle": "hasanthehun",
        "text": "buying a car today",
        "datetime": "10:05 AM · Jan 7, 2022"
    },
    "1416172077661540359": {
        "name": "Sodapoppin",
        "handle": "Sodapoppintv",
        "text": "Masive EGO, literaly cant be stoped. Im the best. I am literaly the best, nothing can stop me, who cares if my dick can fit in a toilet paper roll it DOESNT STOP ME from being the literal best. Get on my level if u can even reach that high, o w8, u cant cuz im so far ahed. Bitch.",
        "datetime": "4:05 PM · Jul 16, 2021"
    },
    "1463756291885580289": {
        "name": "HasanAbi",
        "handle": "hasanthehun",
        "text": "it's a tragic situation but, there has never been a better time for bald american men to fly out to turkey and get a world renowned hair transplant.",
        "datetime": "10:27 PM · Nov 24, 2021"
    },
    "1421626457856430081": {
        "name": "xQc",
        "handle": "xQc",
        "text": "No content",
        "datetime": "5:18 PM · Jul 31, 2021"
    },
    "1403566651753172996": {
        "name": "Sykkuno",
        "handle": "Sykkuno",
        "text": "toast is helping me pick out a suit!! :D",
        "datetime": "9:15 PM · Jun 11, 2021"
    },
    "1455621475256446978": {
        "name": "MoistCr1TiKaL",
        "handle": "MoistCr1TiKaL",
        "text": "Holy shit if you lost money on the Squid Game crypto token, you absolutely deserve it. What a silly scam",
        "datetime": "12:42 PM · Nov 2, 2021"
    },
    "1469835566971686912": {
        "name": "HasanAbi",
        "handle": "hasanthehun",
        "text": "if you post on reddit you should go to jail. yes this includes myself.",
        "datetime": "5:04 PM · Dec 11, 2021"
    },
    "1479622627111030785": {
        "name": "Pokimane",
        "handle": "pokimanelol",
        "text": "the fire nation attacked",
        "datetime": "5:14 PM · Jan 7, 2022"
    },
    "1474936962691866626": {
        "name": "Sodapoppin",
        "handle": "Sodapoppintv",
        "text": "To celebrate Christmas I’m gonna eat a red and green skittle at the same time and nobody can stop me.",
        "datetime": "6:55 PM · Dec 25, 2021",
        "bait": "Sykkuno"
    },
    "1477822356798128129": {
        "name": "xQc",
        "handle": "xQc",
        "text": "I'll give you a free rundown. Call me on my cell. Americas next master streamer? PauseChamp",
        "datetime": "6:01 PM · Jan 2, 2022"
    },
    "1392711741839056897": {
        "name": "Sykkuno",
        "handle": "Sykkuno",
        "text": "rainbow road !!",
        "datetime": "10:22 PM · May 12, 2021"
    },
    "1469636074364305419": {
        "name": "xQc",
        "handle": "xQc",
        "text": "MASTERCHEF IS ONE OF THE MOST ENTERTAINING PIECE OF CONTENT I'VE BINGED IN THE LONGEST TIME AND ALL I EAT IS FAST FOOD, BOOGERS AND TOENAILS. EXPLAIN?",
        "datetime": "3:51 AM · Dec 11, 2021"
    },
    "1412530085240852482": {
        "name": "Pokimane",
        "handle": "pokimanelol",
        "text": "all moved in, any girls wanna come over?",
        "datetime": "2:53 PM · Jul 6, 2021"
    },
    "1474866143571505153": {
        "name": "Sykkuno",
        "handle": "Sykkuno",
        "text": "can i get that bidet i won like a month ago",
        "datetime": "2:14 PM · Dec 25, 2021",
        "bait": "Ludwig"
    },
    "1442229572209160195": {
        "name": "Mizkif",
        "handle": "REALMizkif",
        "text": "I miss Twitch 😔",
        "datetime": "1:48 PM · Sep 26, 2021",
        "bait": "Ludwig"
    },
    "1441928787323756551": {
        "name": "MoistCr1TiKaL",
        "handle": "MoistCr1TiKaL",
        "text": "Cumming and shitting, the strongest combo the human body has",
        "datetime": "5:53 PM · Sep 25, 2021",
        "bait": "Sodapoppin"
    },
    "1479916009368604673": {
        "name": "HasanAbi",
        "handle": "hasanthehun",
        "text": "THE MASTERCHEF META IS OVER!",
        "datetime": "12:40 PM · Jan 8, 2022",
        "bait": "xQc"
    },
    "1475201514750853130": {
        "name": "Sodapoppin",
        "handle": "Sodapoppintv",
        "text": "Ok im live doing this...stream. $25,000 worth of trash picked out by viewers gonna open em in a bit here but im live",
        "datetime": "12:27 PM · Dec 26, 2021",
        "bait": "Ludwig"
    },
    "1463574988506554376": {
        "name": "HasanAbi",
        "handle": "hasanthehun",
        "text": "all the other twitch streamers are getting mclarens, gtrs, r8s and 911 gt3s but everyone knows the v6 toyota camry sport edition w the leather interior seats (heated) has the best cussy",
        "datetime": "10:27 AM · Nov 24, 2021",
        "bait": "Ludwig"
    }
}

export const OTV_TweetsBank = {
    "1475366573648986112": {
        "name": "Sydeon",
        "handle": "Sydeon",
        "text": "I’ve watched Spiderman: No Way Home, The Matrix Ressurections, and Don’t Look Up over my break. Surprisingly enough, Don’t Look Up was my favorite of the 3!",
        "datetime": "11:22 PM · Dec 26, 2021"
    },
    "1445429328494223361": {
        "name": "Masayoshi",
        "handle": "ItzMasayoshi",
        "text": "Anyone that grew up playing video games against their older sibling has technically been smurfed on for the majority of their life",
        "datetime": "9:43 AM · Oct 5, 2021"
    },
    "1471237313900396548": {
        "name": "Yvonnie",
        "handle": "yvonnie",
        "text": "Not getting out of bed until I'm too hungry or have to pee is my new wake up routine 😎",
        "datetime": "1:54 PM · Dec 15, 2021"
    },
    "1506029558448877568": {
        "name": "Yvonnie",
        "handle": "yvonnie",
        "text": "somi likes the new place",
        "datetime": "3:06 PM · Mar 21, 2022"
    },
    "1522330694474633216": {
        "name": "Yvonnie",
        "handle": "yvonnie",
        "text": "laughing at jokes that are not about my height 🙂",
        "datetime": "2:41 PM · May 5, 2022"
    },
    "1490090569678266369": {
        "name": "Pokimane",
        "handle": "pokimanelol",
        "text": "hey u. yes, you. i truly hope you find happiness in life. have a good day ❤️",
        "datetime": "2:30 PM · Feb 5, 2022"
    },
    "1541750390349127680": {
        "name": "QuarterJade",
        "handle": "QuarterJade",
        "text": "loving pride & prejudice will forever be one of my main personality traits\n\ni watch it annually and you should too 😌☝️",
        "datetime": "4:48 AM · Jun 28, 2022"
    },
    "1543343338002083840": {
        "name": "QuarterJade",
        "handle": "QuarterJade",
        "text": "i told rae she looked so good i should take a photo of her and she replied “let’s take a photo together instead” 😌🤍",
        "datetime": "2:18 PM · Jul 2, 2022"
    },
    "1467245250624176128": {
        "name": "Yvonnie",
        "handle": "yvonnie",
        "text": "new vlog 😃\n\nsquid game shoot bts, vegas stuff, EDC 2021, and MOREEEE",
        "datetime": "1:31 PM · Dec 4, 2021"
    },
    "1528149844023332864": {
        "name": "Pokimane",
        "handle": "pokimanelol",
        "text": "my brain is 75% song lyrics",
        "datetime": "4:04 PM · May 21, 2022"
    },
    "1329303765506088960": {
        "name": "Michael Reeves",
        "handle": "michaelreeves",
        "text": "Kpop",
        "datetime": "10:01 PM · Nov 18, 2020"
    },
    "1413279019529768965": {
        "name": "Masayoshi",
        "handle": "ItzMasayoshi",
        "text": "wait you guys weren’t kidding avocados do taste like dick",
        "datetime": "4:29 PM · Jul 8, 2021"
    },
    "1481327509807394816": {
        "name": "Yvonnie",
        "handle": "yvonnie",
        "text": "just typed 'uber eats' into uber eats search bar\n\ngood morning",
        "datetime": "10:09 AM · Jan 12, 2022"
    },
    "1525322974626209792": {
        "name": "Michael Reeves",
        "handle": "michaelreeves",
        "text": "Going to punch and be punched tomorrow \n@TheCreatorClash\n \nhttps://thecreatorclash.com",
        "datetime": "8:51 PM · May 13, 2022"
    },
    "1508561362863095809": {
        "name": "Sydeon",
        "handle": "Sydeon",
        "text": "I think we should just eradicate math altogether who is with me",
        "datetime": "2:47 PM · Mar 28, 2022"
    },
    "1506694829350088710": {
        "name": "Scarra",
        "handle": "scarra",
        "text": "sometimes the clown shoes fit a little too well but they're kinda comfy so u keep them on for a while",
        "datetime": "11:10 AM · Mar 23, 2022"
    },
    "1461833921893593089": {
        "name": "Masayoshi",
        "handle": "ItzMasayoshi",
        "text": "I am proud to announce that i’m officially a member of \n@OfflineTV\n!\n\nI hope you’re all just as excited as i am for the new content and memories to come 🥲",
        "datetime": "3:09 PM · Nov 19, 2021",
        "bait": "Sydeon"
    },
    "1362381140862984193": {
        "name": "Michael Reeves",
        "handle": "michaelreeves",
        "text": "Apparently the DMV doesn't think my 2019 Vidcon creator pass is a \"valid form of ID\". the government is a joke",
        "datetime": "4:39 AM · Feb 18, 2021"
    },
    "1466681749887352836": {
        "name": "Yvonnie",
        "handle": "yvonnie",
        "text": "BTS IS SOOOOO HOTTTTTT I'M - 😳😳😳😳",
        "datetime": "12:12 AM · Dec 3, 2021"
    },
    "1457479327583330306": {
        "name": "LilyPichu",
        "handle": "LilyPichu",
        "text": "apparently there is a video of me cursing in tagalog out there 😱 I didn't know \n\nnahihiya ako !! 😱",
        "datetime": "2:45 PM · Nov 7, 2021"
    },
    "1480764955242602498": {
        "name": "DisguisedToast",
        "handle": "DisguisedToast",
        "text": "welp, looks like i'll be seeing you guys in a month",
        "datetime": "8:54 PM · Jan 10, 2022"
    },
    "1521524477351251969": {
        "name": "DisguisedToast",
        "handle": "DisguisedToast",
        "text": "no cap on a stack on god frfr aight bet",
        "datetime": "9:17 AM · May 3, 2022"
    },
    "1475290383055667200": {
        "name": "Masayoshi",
        "handle": "ItzMasayoshi",
        "text": "jodi’s here to save my twitter from being inactive, happy holidays ❄️",
        "datetime": "6:20 PM · Dec 26, 2021"
    },
    "1507407936678612993": {
        "name": "QuarterJade",
        "handle": "QuarterJade",
        "text": "mabel just woke up and she looks like a kid sitting at the kitchen table waiting for her piece of toast so she isn’t late for the bus",
        "datetime": "10:23 AM · Mar 25, 2022"
    },
    "1533189440956354560": {
        "name": "Pokimane",
        "handle": "pokimanelol",
        "text": "just got out of an uber and almost said “thanks, love you” to my driver 😭😭 wtf is wrong w meee",
        "datetime": "1:50 PM · Jun 4, 2022"
    },
    "1518314776740380673": {
        "name": "LilyPichu",
        "handle": "LilyPichu",
        "text": "A fan came up and asked me to wish for them in genshin and I did and they rolled sayu 😭💚",
        "datetime": "12:43 PM · Apr 24, 2022"
    },
    "1505221597803819012": {
        "name": "LilyPichu",
        "handle": "LilyPichu",
        "text": "was so ready to cosplay but then I just had to go and order enough Wendy's for three people and eat it all",
        "datetime": "9:36 AM · Mar 19, 2022"
    },
    "1470897240684126208": {
        "name": "Sydeon",
        "handle": "Sydeon",
        "text": "John just asked me a math question and, man, all I gotta say is thank god we stream for a living",
        "datetime": "3:23 PM · Dec 14, 2021",
        "bait": "QuarterJade"
    },
    "1509681794773258243": {
        "name": "Michael Reeves",
        "handle": "michaelreeves",
        "text": "Guys the upload button is actually in the top right of the youtube page, been looking for that for a while\n\nFish Trades Stocks, link in comment",
        "datetime": "4:59 PM · Mar 31, 2022"
    },
    "1461031447985197056": {
        "name": "DisguisedToast",
        "handle": "DisguisedToast",
        "text": "Thank you, \n@FacebookGaming\n \n\nToday marks my last day livestreaming on the platform\n\nThe last 2 years have been some of the best moments of my career and I wish them nothing but success. Can’t wait to see them achieve greater things in the future.",
        "datetime": "10:00 AM · Nov 17, 2021"
    },
    "1535174781741375488": {
        "name": "Scarra",
        "handle": "scarra",
        "text": "New tft set isn't bad but I swear if I die to another astral reroll with an unkillable illaoi I'm going to fucking lose it",
        "datetime": "1:19 AM · Jun 10, 2022",
        "bait": "DisguisedToast"
    },
    "1425737931050741767": {
        "name": "Masayoshi",
        "handle": "ItzMasayoshi",
        "text": "NEW RECORD: only took me 29 days to get my first noise complaint in the new place",
        "datetime": "1:36 AM · Aug 12, 2021"
    },
    "1530630190291746816": {
        "name": "QuarterJade",
        "handle": "QuarterJade",
        "text": "i had a dream where the person i was chatting with suddenly started choking me and i woke up coughing just to find genji trying to cuddle my neck with his entire body",
        "datetime": "12:20 PM · May 28, 2022"
    },
    "1463996256091402249": {
        "name": "Masayoshi",
        "handle": "ItzMasayoshi",
        "text": "🦃💨 HAPPY THANKSGIVING EVERYONE!! unless your name is Matthew, I hope your turkey is dry",
        "datetime": "2:21 PM · Nov 25, 2021"
    },
    "1466076747481321474": {
        "name": "Masayoshi",
        "handle": "ItzMasayoshi",
        "text": "GOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOD mornin y'all",
        "datetime": "8:08 AM · Dec 1, 2021"
    },
    "1542921114396999680": {
        "name": "DisguisedToast",
        "handle": "DisguisedToast",
        "text": "spent 15 minutes sitting in a lyft with cockroaches crawling across the seats because im too introverted to say anything",
        "datetime": "10:20 AM · Jul 1, 2022"
    },
    "1459625150324752385": {
        "name": "Scarra",
        "handle": "scarra",
        "text": "I feel like the biggest arcane shill I'm peddling arcane in every twitch chat pls do not perceive",
        "datetime": "12:52 PM · Nov 13, 2021"
    },
    "1480271080015077376": {
        "name": "Sydeon",
        "handle": "Sydeon",
        "text": "I used to want thick thighs just to be thick, but now I want thick thighs so Crash has more surface area to lay on",
        "datetime": "12:11 PM · Jan 9, 2022"
    },
    "1489816960766332928": {
        "name": "QuarterJade",
        "handle": "QuarterJade",
        "text": "i want my body to move like Doja Cat's but instead i look like Tina from Bob's Burgers",
        "datetime": "8:23 PM · Feb 4, 2022"
    },
    "1542581243828588544": {
        "name": "LilyPichu",
        "handle": "LilyPichu",
        "text": "when I'm stressed I love smelling my dog like really getting my nose into her fur she smells so good kinda like chicken or one of those vanilla scented candles",
        "datetime": "11:50 AM · Jun 30, 2022"
    },
    "1499088224030846979": {
        "name": "Pokimane",
        "handle": "pokimanelol",
        "text": "GTA RP has one of the kindest & most welcoming communities i’ve ever come across 🥺",
        "datetime": "10:24 AM · Mar 2, 2022"
    },
    "1543815394506317824": {
        "name": "Scarra",
        "handle": "scarra",
        "text": "I just walked into a glass window at a bar \n\nI'm never going back there I am so embarrassed",
        "datetime": "9:34 PM · Jul 3, 2022"
    },
    "1479325708086218753": {
        "name": "Scarra",
        "handle": "scarra",
        "text": "I sat down and my fat ass broke Yvonne's tv by cracking the screen behind me...\n\nGod damnit",
        "datetime": "9:35 PM · Jan 6, 2022"
    },
    "1500547304931004416": {
        "name": "Pokimane",
        "handle": "pokimanelol",
        "text": "anime is real",
        "datetime": "11:02 AM · Mar 6, 2022"
    },
    "1479959569447018497": {
        "name": "DisguisedToast",
        "handle": "DisguisedToast",
        "text": "what do you mean I can't just watch anime on my stream for hours and hours while barely saying anything?",
        "datetime": "3:33 PM · Jan 8, 2022"
    },
    "1538982201999536128": {
        "name": "Sydeon",
        "handle": "Sydeon",
        "text": "Reyna in the new cinematic is making me FEEL THINGS",
        "datetime": "1:28 PM · Jun 20, 2022"
    },
    "1457132887128510465": {
        "name": "Masayoshi",
        "handle": "ItzMasayoshi",
        "text": "learn to control your emotions not just your recoil",
        "datetime": "4:48 PM · Nov 6, 2021"
    },
    "1540858800206319616": {
        "name": "QuarterJade",
        "handle": "QuarterJade",
        "text": "i just did my makeup for the first time in 2+ weeks and oh my god i am a bombshell i am the hottest thing to have ever walked this earth i have never seen such beauty, before i was crust and now i am sizzling",
        "datetime": "5:45 PM · Jun 25, 2022"
    },
    "1379585748333166593": {
        "name": "Michael Reeves",
        "handle": "michaelreeves",
        "text": "Oh shit I forgot to make YouTube videos for the past 6 months",
        "datetime": "5:04 PM · Apr 6, 2021"
    },
    "1483664400217042944": {
        "name": "LilyPichu",
        "handle": "LilyPichu",
        "text": "I am very good at sleeping",
        "datetime": "8:55 PM · Jan 18, 2022"
    },
    "1491255405242388481": {
        "name": "Sydeon",
        "handle": "Sydeon",
        "text": "Already having sooo much fun playing \n@playlostark\n! The fighting is SO SATISFYING and this is the first time the start of an mmo didn’t bore me to death. I will be spending all day tomorrow playing this eeeeep. Also look at how hot my shadowhunter is 🤪",
        "datetime": "7:39 PM · Feb 8, 2022"
    },
    "1470652741345103879": {
        "name": "LilyPichu",
        "handle": "LilyPichu",
        "text": "things will be o k a y ⭐",
        "datetime": "11:11 PM · Dec 13, 2021"
    },
    "1516556532900499465": {
        "name": "Sydeon",
        "handle": "Sydeon",
        "text": "Not much makes me happier than nature documentaries",
        "datetime": "4:17 PM · Apr 19, 2022"
    },
    "1525636693591609344": {
        "name": "LilyPichu",
        "handle": "LilyPichu",
        "text": "MICHAELLLLLLLLLLLLLL",
        "datetime": "5:38 PM · May 14, 2022"
    },
    "1540767388697628673": {
        "name": "Pokimane",
        "handle": "pokimanelol",
        "text": "*hugs*",
        "datetime": "11:42 AM · Jun 25, 2022"
    },
    "1515393481501335554": {
        "name": "DisguisedToast",
        "handle": "DisguisedToast",
        "text": "Thank you to all the players, admins, viewers that tuned into the Rust event these last few days\n\nEven though in ended in unfortunate circumstances, I'm proud to have been a part of it and the team we put together❤️",
        "datetime": "11:15 AM · Apr 16, 2022"
    },
    "1493694978458939394": {
        "name": "Yvonnie",
        "handle": "yvonnie",
        "text": "how's it possible to be super bored but also not want to do anything at the same time what is this",
        "datetime": "1:13 PM · Feb 15, 2022"
    },
    "1462824808912023554": {
        "name": "Scarra",
        "handle": "scarra",
        "text": "Ok this is really strange but I've been able to occasionally taste my antibiotic eye drops when I use them on my eyes\n\nLike I will get the flavor in my mouth hours after I apply them\n\nAm I becoming a monster doc give it to me straight",
        "datetime": "8:46 AM · Nov 22, 2021"
    },
    "1474877795805253632": {
        "name": "Yvonnie",
        "handle": "yvonnie",
        "text": "MERRY CHRISTMAS!!!!! hope everyone has a safe and comfy one ☺🎄🎁✨",
        "datetime": "3:00 PM · Dec 25, 2021",
        "bait": "LilyPichu"
    },
    "1516207448842924034": {
        "name": "Sydeon",
        "handle": "Sydeon",
        "text": "誰も私がバターでできているとあなたに言いませんでしたか？",
        "datetime": "5:10 PM · Apr 18, 2022"
    },
    "1517577811111915523": {
        "name": "Pokimane",
        "handle": "pokimanelol",
        "text": "decided to name him Beau! ☺️",
        "datetime": "11:55 AM · Apr 22, 2022"
    },
    "1528748658820120577": {
        "name": "Scarra",
        "handle": "scarra",
        "text": "My legs do not work anymore after edc oh god pls amputate asap",
        "datetime": "7:44 AM · May 23, 2022"
    },
    "1386207997144100866": {
        "name": "Michael Reeves",
        "handle": "michaelreeves",
        "text": "jimin burger NOW",
        "datetime": "11:38 PM · Apr 24, 2021"
    },
    "1525499328776744965": {
        "name": "QuarterJade",
        "handle": "QuarterJade",
        "text": "i am addicted to valorant and minecraft",
        "datetime": "8:32 AM · May 14, 2022"
    },
    "1521650737083666434": {
        "name": "DisguisedToast",
        "handle": "DisguisedToast",
        "text": "sometimes i feel like i have to be mean to people that i care about because if im nice and try to be close with them that means opening up myself to be vulnerable and thats terrifying because what if they reject that part of me so i act distant instead so that it'll never happen",
        "datetime": "5:39 PM · May 3, 2022"
    },
    "1524886798609944576": {
        "name": "QuarterJade",
        "handle": "QuarterJade",
        "text": "when i was growing up, my mom would ground me if she saw i was swearing on social media\n\nso when i wrote “shit” just now, i felt her presence looming over me",
        "datetime": "3:58 PM · May 12, 2022"
    },
    "1525038625037115392": {
        "name": "LilyPichu",
        "handle": "LilyPichu",
        "text": "I want to stick a long metal straw down my throat and have someone suck up all the mucus or carve a hole in my chest and scoop out all the mucus with an ice cream spoon",
        "datetime": "2:01 AM · May 13, 2022",
        "bait": "Michael Reeves"
    },
    "1419099261925412864": {
        "name": "Michael Reeves",
        "handle": "michaelreeves",
        "text": "i have never in my life mentally registered that Sweden and Switzerland are different places",
        "datetime": "5:56 PM · Jul 24, 2021"
    },
    "1514653868621111299": {
        "name": "Pokimane",
        "handle": "pokimanelol",
        "text": "accidentally said five guys at freddy’s instead of five nights 😭",
        "datetime": "10:16 AM · Apr 14, 2022"
    },
    "1464028018880352256": {
        "name": "DisguisedToast",
        "handle": "DisguisedToast",
        "text": "I turn 30 today.\n\nOne of the goals I set for myself over the last year was to make more of an effort in being physically active and dressing better before hitting my 30s.\n\nLast year I weight 165lbs, today I'm 135lbs.",
        "datetime": "4:27 PM · Nov 25, 2021"
    },
    "1421971323555704832": {
        "name": "Michael Reeves",
        "handle": "michaelreeves",
        "text": "Find you someone who microwave corn <3",
        "datetime": "4:09 PM · Aug 1, 2021",
        "bait": "LilyPichu"
    },
    "1530110133379792897": {
        "name": "Scarra",
        "handle": "scarra",
        "text": "I don't get the morbius memes and I kinda wanna watch the movie just so I can understand what the fuck morbin time is",
        "datetime": "1:54 AM · May 27, 2022"
    }
}

export const OTK_TweetsBank = {
    "1543660523094515713": {
        "name": "Esfand",
        "handle": "EsfandTV",
        "text": "I’m looking for a fertile woman to do the baby thing for content for a couple days week maybe idk, just think it would be better with a real baby. You can keep the kid when we’re done with it and I can even throw in an autograph or something lmk",
        "datetime": "11:18 AM · Jul 3, 2022",
        "bait": "Mizkif"
    },
    "1522428266241748992": {
        "name": "Esfand",
        "handle": "EsfandTV",
        "text": "how tf South Korea so clean when I can’t find a goddamn trash can anywhere",
        "datetime": "9:09 PM · May 5, 2022",
        "bait": "Mizkif"
    },
    "1546208809160478720": {
        "name": "Rich Campbell",
        "handle": "richwcampbell",
        "text": "Oh no we have been bad boys 💦💦💦 so hot in Texas 💦💦💦what would you do to cool us off ?",
        "datetime": "12:04 PM · Jul 10, 2022"
    },
    "1525743013938581507": {
        "name": "Esfand",
        "handle": "EsfandTV",
        "text": "Oh how unfortunate, they ran out of the EXTRA LARGE CONDOMS at the front desk of the hotel. Sorry ladies, no action from me tonight!",
        "datetime": "12:40 AM · May 15, 2022"
    },
    "1531774809499115520": {
        "name": "Asmongold",
        "handle": "Asmongold",
        "text": "A lot of people out there saying I have bad takes\nI don't",
        "datetime": "4:09 PM · May 31, 2022"
    },
    "1536818646668005378": {
        "name": "Bruce",
        "handle": "raycondones",
        "text": "I like franks and im tired of acting like i don’t. Social media makes them seem like some sort of forbidden food, but in reality they’re just little morsels of flavor filled cylindrical meat sticks. Rate the eats.",
        "datetime": "2:11 PM · Jun 14, 2022"
    },
    "1522833083753545730": {
        "name": "Cyr",
        "handle": "Cyr",
        "text": "I’m pregnant",
        "datetime": "11:57 PM · May 6, 2022"
    },
    "1513067490733170691": {
        "name": "Cyr",
        "handle": "Cyr",
        "text": "EVERYTIME I USE SHAZAM ON A QUIET SONG SOMEONE IN THE RESTAURANT STARTS TALKING LOUD. JUST LET ME GET MY FUCKING MUSIC NUT BEFORE THE SONG ENDS GODDAMMIT",
        "datetime": "1:12 AM · Apr 10, 2022"
    },
    "1536037583867858945": {
        "name": "Jschlatt",
        "handle": "Jschlatt",
        "text": "It's okay man theres an uncensored nutsack in one of my videos and its not even demonetized",
        "datetime": "10:27 AM · Jun 12, 2022"
    },
    "1504297206156832769": {
        "name": "Esfand",
        "handle": "EsfandTV",
        "text": "Final 3 hours of the Uncapped Subathon /salute",
        "datetime": "8:22 PM · Mar 16, 2022"
    },
    "1526741249528676354": {
        "name": "Mizkif",
        "handle": "REALmizkif",
        "text": "Who would box me who is actually my size\nI'm 5'8 - 174lbs \nSERIOUS INQUIRES ONLY",
        "datetime": "6:47 PM · May 17, 2022"
    },
    "1540094236686798850": {
        "name": "Jschlatt",
        "handle": "Jschlatt",
        "text": "@ConnorEatsPants get off that stage bro",
        "datetime": "3:07 PM · Jun 23, 2022"
    },
    "1525215408856485888": {
        "name": "Bruce",
        "handle": "raycondones",
        "text": "Bro GENES ARE CRAZY I DEAD ASS GOT MY MOM AN DAD FEATURES",
        "datetime": "1:44 PM · May 13, 2022"
    },
    "1539643814071406592": {
        "name": "Cyr",
        "handle": "Cyr",
        "text": "Yo! Good Morning Noobs- I mean gamers! LOL! Will be getting an early start today! I look forward to providing SO much entertainment for you! Can I get maybe… a POGGERS in chat?",
        "datetime": "9:17 AM · Jun 22, 2022"
    },
    "1538547779106136066": {
        "name": "Nmplol",
        "handle": "nmplol",
        "text": "Thanks for watching the stream!\nI love cars and I love seeing other people who also love cars.\nSharing that love with you guys on twitch is really fun for me thanks for being apart of it!",
        "datetime": "8:42 AM · Jun 19, 2022"
    },
    "1483229831995305991": {
        "name": "Mizkif",
        "handle": "REALmizkif",
        "text": "Poki",
        "datetime": "4:08 PM · Jan 17, 2022"
    },
    "1541485596626980865": {
        "name": "Rich Campbell",
        "handle": "richwcampbell",
        "text": "I casted smite for the first time 7 years ago today. After all of my hard work and dedication to my craft I have finally made it - today I get to be on Mizkifs stream!",
        "datetime": "11:16 AM · Jun 27, 2022"
    },
    "1534995379279699968": {
        "name": "Tectone",
        "handle": "Tectone",
        "text": "Vtubers make me happy :D",
        "datetime": "1:26 PM · Jun 9, 2022"
    },
    "1533917639214505987": {
        "name": "Tectone",
        "handle": "Tectone",
        "text": "Guys please stop flooding my posts with hentai, I'm on a plane.",
        "datetime": "2:03 PM · Jun 6, 2022"
    },
    "1507919100974092294": {
        "name": "Tips Out",
        "handle": "TipsOut",
        "text": "Tomorrow. Shareholders meeting. \nBe there.",
        "datetime": "8:15 PM · Mar 26, 2022"
    },
    "1525624811069886464": {
        "name": "Jschlatt",
        "handle": "Jschlatt",
        "text": "@JustaMinx stay the fuck away from me",
        "datetime": "4:51 PM · May 14, 2022"
    },
    "1536457871713611782": {
        "name": "Bruce",
        "handle": "raycondones",
        "text": "Yo Stream?",
        "datetime": "2:17 PM · Jun 13, 2022",
        "bait": "Mizkif"
    },
    "1520652123137994752": {
        "name": "Rich Campbell",
        "handle": "richwcampbell",
        "text": "She’s trying to make me shave my mustache.",
        "datetime": "11:31 PM · Apr 30, 2022",
        "bait": "Nmplol"
    },
    "1539062883048464384": {
        "name": "Asmongold",
        "handle": "Asmongold",
        "text": "Never in my life did I imagine a US senator would engage in a conversation with me regarding P2W and lootboxes in video games LOL\nVery excited!",
        "datetime": "6:49 PM · Jun 20, 2022"
    },
    "1535123067122307072": {
        "name": "Esfand",
        "handle": "EsfandTV",
        "text": "I just went to a gamer bar in LA and didn’t get recognized, my ego is shattered.",
        "datetime": "9:53 PM · Jun 9, 2022"
    },
    "1485783154409488386": {
        "name": "Jschlatt",
        "handle": "Jschlatt",
        "text": "AT LONG LAST, I HAVE BEEN RE-VERIFIED. I PROMISE I WILL NOT THREATEN THE QUEEN THIS TIME.",
        "datetime": "5:14 PM · Jan 24, 2022"
    },
    "1539980379666452480": {
        "name": "Tectone",
        "handle": "Tectone",
        "text": "FRICK",
        "datetime": "7:35 AM · Jun 23, 2022",
        "bait": "Emiru"
    },
    "1516451620652937217": {
        "name": "Rich Campbell",
        "handle": "richwcampbell",
        "text": "I just woke up. Is world of Warcraft good again?",
        "datetime": "9:20 AM · Apr 19, 2022",
        "bait": "Esfand"
    },
    "1530619839911444480": {
        "name": "Esfand",
        "handle": "EsfandTV",
        "text": "I saw this piece of wood at the store for 60 bucks and I bought it because it looked like Texas",
        "datetime": "11:39 AM · May 28, 2022"
    },
    "1524488420755591169": {
        "name": "Rich Campbell",
        "handle": "richwcampbell",
        "text": "Everyone who got banned for using add ons is worse than me at final fantasy.",
        "datetime": "1:35 PM · May 11, 2022",
        "bait": "Asmongold"
    },
    "1545554488521621527": {
        "name": "Rich Campbell",
        "handle": "richwcampbell",
        "text": "Being back in Texas with everyone is so nice",
        "datetime": "4:44 PM · Jul 8, 2022",
        "bait": "Emiru"
    },
    "1507841781051842567": {
        "name": "Emiru",
        "handle": "emiru",
        "text": "@nmplol you have a very charming smile and i like your posture keep up the good work! #ad",
        "datetime": "3:07 PM · Mar 26, 2022"
    },
    "1533570691072679939": {
        "name": "Mizkif",
        "handle": "REALmizkif",
        "text": "Don't rock the boat. BE the boat.",
        "datetime": "3:05 PM · Jun 5, 2022",
        "bait": "Tips Out"
    },
    "1522482335836299264": {
        "name": "Mizkif",
        "handle": "REALmizkif",
        "text": "I was walking around the gym and everyone kept staring at me and I thought it’s because I was swole \nBut it’s because when I did squats my balls came out of my shorts",
        "datetime": "12:44 AM · May 6, 2022"
    },
    "1518722405346250753": {
        "name": "Jschlatt",
        "handle": "Jschlatt",
        "text": "“well twitter was fun while it lasted” shut the fuck up. this app always sucked ass",
        "datetime": "3:43 PM · Apr 25, 2022"
    },
    "1530353699427590148": {
        "name": "Cyr",
        "handle": "Cyr",
        "text": "you’re cool remember that",
        "datetime": "6:02 PM · May 27, 2022",
        "bait": "Tips Out"
    },
    "1532654362031538178": {
        "name": "Esfand",
        "handle": "EsfandTV",
        "text": "I love seeing people tweet pictures with their new OTK merch and how happy they are 😁",
        "datetime": "2:24 AM · Jun 3, 2022",
        "bait": "Emiru"
    },
    "1536405173781909504": {
        "name": "Tectone",
        "handle": "Tectone",
        "text": "Nice cock",
        "datetime": "10:48 AM · Jun 13, 2022"
    },
    "1526404588433203200": {
        "name": "Jschlatt",
        "handle": "Jschlatt",
        "text": "Call that shit Jambo",
        "datetime": "8:29 PM · May 16, 2022"
    },
    "1532775077137219584": {
        "name": "Cyr",
        "handle": "Cyr",
        "text": "Big decisions today. Should I re-sign my contract with Twitch, or should I say fuck it and sign with The United States Marine Corps? Trying to weigh benefits- would appreciate input thanks",
        "datetime": "10:23 AM · Jun 3, 2022"
    },
    "1477830138989580290": {
        "name": "Emiru",
        "handle": "emiru",
        "text": "I‘m joining \n@OTKnetwork! 👑",
        "datetime": "6:32 PM · Jan 2, 2022",
        "bait": "Bruce"
    },
    "1509881680810684430": {
        "name": "Nmplol",
        "handle": "nmplol",
        "text": "I hate April Fools Day.\nNone of you are funny.\nStop.",
        "datetime": "6:13 AM · Apr 1, 2022",
        "bait": "Jschlatt"
    },
    "1540459797740388353": {
        "name": "Emiru",
        "handle": "emiru",
        "text": "egg",
        "datetime": "3:20 PM · Jun 24, 2022",
        "bait": "Mizkif"
    },
    "1515912686655188996": {
        "name": "Mizkif",
        "handle": "REALmizkif",
        "text": "@DojaCat would you like to come on my stream sometime and react to the top 100 songs of all time???!?",
        "datetime": "9:38 PM · Apr 17, 2022"
    },
    "1543998963271041026": {
        "name": "Asmongold",
        "handle": "Asmongold",
        "text": "I am bad at video games",
        "datetime": "9:43 AM · Jul 4, 2022"
    },
    "1487679043013128196": {
        "name": "Emiru",
        "handle": "emiru",
        "text": "reallly really really really really really big boobs",
        "datetime": "10:48 PM · Jan 29, 2022"
    },
    "1505000472918245379": {
        "name": "Emiru",
        "handle": "emiru",
        "text": "Couldn't have asked for this tournament to go better ❤️ Incredible performance from the players, casters and production. So proud of everybody!! :D\nCongrats to our first OTK RIFT champions! \n@Thebuddha_3 @xQc @Sykkuno @blaustoise @Harryo_",
        "datetime": "6:57 PM · Mar 18, 2022"
    },
    "1510529439708454912": {
        "name": "Mizkif",
        "handle": "REALmizkif",
        "text": "I've loved every second of r/place. Been some of the most fun i've had in 3 years of streaming",
        "datetime": "1:07 AM · Apr 3, 2022"
    },
    "1511408765932818438": {
        "name": "Nmplol",
        "handle": "nmplol",
        "text": "A viewer did an in-depth analysis of my YouTube channels statistics.\nThe results are shocking.\n• Videos with me in the thumbnail get approximately 30,000 less views on average than videos without me in the thumbnail.",
        "datetime": "11:21 AM · Apr 5, 2022"
    },
    "1532027620292014081": {
        "name": "Jschlatt",
        "handle": "Jschlatt",
        "text": "Charlie I love you but I’ve been trying to pull out of this parking space for fifteen minutes please get out of the road",
        "datetime": "8:53 AM · Jun 1, 2022"
    },
    "1540943019955855360": {
        "name": "Tectone",
        "handle": "Tectone",
        "text": "Smite is my favorite game right now, thanks to all y'all who have been enjoying the streams of that lately. Post-tourney we're still gonna play it TBH much to my surprise the community has been super warm and welcoming so thanks all who are new to my streams.",
        "datetime": "11:20 PM · Jun 25, 2022",
        "bait": "Mizkif"
    },
    "1528946150048399360": {
        "name": "Asmongold",
        "handle": "Asmongold",
        "text": "One of my favorite parts of the day is cozying up in bed under the blankets and reading hate threads about myself",
        "datetime": "8:49 PM · May 23, 2022",
        "bait": "Mizkif"
    },
    "1504676505653235748": {
        "name": "Asmongold",
        "handle": "Asmongold",
        "text": "The worst thing about vtubers is that they remind me that catgirls aren't real",
        "datetime": "9:30 PM · Mar 17, 2022",
        "bait": "Tectone"
    },
    "1546168909027573767": {
        "name": "Nmplol",
        "handle": "nmplol",
        "text": "My girlfriend usually walks faster than me.\nI wanted to see if she’d notice she left me behind.\nShe didn’t.",
        "datetime": "9:26 AM · Jul 10, 2022"
    },
    "1542238179713028100": {
        "name": "Tips Out",
        "handle": "TipsOut",
        "text": "I hate all streamers equally. No discrimination here.",
        "datetime": "1:06 PM · Jun 29, 2022"
    },
    "1523454247060533249": {
        "name": "Bruce",
        "handle": "raycondones",
        "text": "yo 2,000 likes an 2,000 replies in 2 minutes for stream",
        "datetime": "5:06 PM · May 8, 2022"
    },
    "1540362267270823936": {
        "name": "Bruce",
        "handle": "raycondones",
        "text": "Women Are Smarter",
        "datetime": "8:52 AM · Jun 24, 2022",
        "bait": "Emiru"
    },
    "1474222457716363289": {
        "name": "Tips Out",
        "handle": "TipsOut",
        "text": "To all those trying to make a career out of their passion despite those around you discouraging you, press on. \nAnd if you find someone who supports you through it all, hold them close and be to them what they are to you. \nThose are the gems of life.",
        "datetime": "7:36 PM · Dec 23, 2021"
    },
    "1545963295063941121": {
        "name": "Emiru",
        "handle": "emiru",
        "text": "i just destroyed doublelift lol",
        "datetime": "7:49 PM · Jul 9, 2022"
    },
    "1510309770170978307": {
        "name": "Bruce",
        "handle": "raycondones",
        "text": "You ever take a shit so bad you start shaking?",
        "datetime": "10:34 AM · Apr 2, 2022"
    },
    "1543989425629175810": {
        "name": "Cyr",
        "handle": "Cyr",
        "text": "If I’m not live today precisely at 12:30 CST, I’m quitting OTK & deleting my channel, then going back to my lizard folk on the planet Qįœżżvœb, where my father (the High Chancellor of the Northern Sector) will shame me in front of the Galactic Hand, cataclysmically ending life.",
        "datetime": "9:05 AM · Jul 4, 2022"
    },
    "1506659122728611842": {
        "name": "Nmplol",
        "handle": "nmplol",
        "text": "Bastard so much better for you guys and you can just do that for you too and you want me too and you want me to ask for it and then I don’t want you too and I want you too much and stuff I can just say that I’m sorry just not to do anything wrong but I’m so tired and tired",
        "datetime": "8:48 AM · Mar 23, 2022"
    },
    "1508269913592455170": {
        "name": "Bruce",
        "handle": "raycondones",
        "text": "Will Smith just like me",
        "datetime": "7:29 PM · Mar 27, 2022"
    },
    "1488625369485910018": {
        "name": "Emiru",
        "handle": "emiru",
        "text": "FUCK YOU RIOT",
        "datetime": "1:28 PM · Feb 1, 2022"
    },
    "1537550148582121477": {
        "name": "Tectone",
        "handle": "Tectone",
        "text": "My kitties eggy and whooper lost their balls today, ima take the day off and make sure they are okay today :D cya tomo!",
        "datetime": "2:38 PM · Jun 16, 2022"
    },
    "1538665144628256769": {
        "name": "Tectone",
        "handle": "Tectone",
        "text": "What type of beta cuck do u gotta be to like being called a \"good boy\" ?",
        "datetime": "4:28 PM · Jun 19, 2022"
    },
    "1525741703130451968": {
        "name": "Cyr",
        "handle": "Cyr",
        "text": "I think @xQc and I would put on the crackhead juice fight of a lifetime",
        "datetime": "12:35 AM · May 15, 2022"
    },
    "1528850103305490433": {
        "name": "Nmplol",
        "handle": "nmplol",
        "text": "Amber Heard was in Aquaman?",
        "datetime": "2:27 PM · May 23, 2022",
        "bait": "Asmongold"
    },
    "1534596666472288256": {
        "name": "Rich Campbell",
        "handle": "richwcampbell",
        "text": "Usually I get the Asmon host, but today I’m hosting with Asmon",
        "datetime": "11:02 AM · Jun 8, 2022"
    },
    "1541549950940729346": {
        "name": "Mizkif",
        "handle": "REALmizkif",
        "text": "SMITEEEEEEEEEEEEEEEE",
        "datetime": "3:32 PM · Jun 27, 2022"
    },
    "1467905350087188486": {
        "name": "Tips Out",
        "handle": "TipsOut",
        "text": "There seems to be some confusion as to whether or not @shroud owns @OTKnetwork so I thought I’d clarify:\nShroud owns everything. OTK, my car, Twitch, YouTube, Chipotle, and more. \nIf it’s a thing and it’s ownable, Shroud owns it.",
        "datetime": "9:14 AM · Dec 6, 2021"
    },
    "1532527419915116544": {
        "name": "Tips Out",
        "handle": "TipsOut",
        "text": "I vividly remember the outrage in 2010 when the first paid cosmetic item hit the WoW store (celestial steed). Players were livid.\nCrazy to see how far things have come; paid cosmetics are encouraged by gamers and p2w/lootboxes are completely normalized. \nTimes change I guess.",
        "datetime": "5:59 PM · Jun 2, 2022"
    },
    "1483786796555145219": {
        "name": "Tips Out",
        "handle": "TipsOut",
        "text": "Today is the day you wake up early and start working on that dream idea you’ve been hanging onto. \nYes, today. Not tomorrow, not next week. \nNow.",
        "datetime": "5:01 AM · Jan 19, 2022"
    },
    "1520600616455655425": {
        "name": "Asmongold",
        "handle": "Asmongold",
        "text": "I should buy an alpaca",
        "datetime": "8:06 PM · Apr 30, 2022",
        "bait": "Emiru"
    },
    "1513176193977229316": {
        "name": "Asmongold",
        "handle": "Asmongold",
        "text": "Genshin Impact",
        "datetime": "8:24 AM · Apr 10, 2022",
        "bait": "Tectone"
    },
    "1533479694292721664": {
        "name": "Tips Out",
        "handle": "TipsOut",
        "text": "The OTK Games Expo is in 3 days. The OTK Games Expo is in 3 days. The OTK Games Expo is in 3 days. The OTK Games Expo is in 3 days. The OTK Games Expo is in 3 days. The OTK Games Expo is in 3 days. The OTK Games Expo is in 3 days. The OTK Games Expo is in 3 days. The OTK Games Ex",
        "datetime": "9:03 AM · Jun 5, 2022"
    },
    "1537227313527783424": {
        "name": "Nmplol",
        "handle": "nmplol",
        "text": "WHY IS OTK STEALING MY TWEETS",
        "datetime": "5:15 PM · Jun 15, 2022"
    }
}