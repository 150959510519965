export const OTK_ChoicesBank = [
    "Asmongold",
    "Esfand",
    "Rich Campbell",
    "Mizkif",
    "Tips Out",
    "Jschlatt",
    "Cyr",
    "Tectone",
    "Emiru",
    "Bruce"
]

export const OTV_ChoicesBank = [
    "Scarra",
    "Pokimane",
    "LilyPichu",
    "DisguisedToast",
    "Yvonnie",
    "Michael Reeves",
    "Sydeon",
    "Masayoshi",
    "QuarterJade"
]

export const LSF_ChoicesBank = [
    "Mizkif",
    "HasanAbi",
    "Sykkuno",
    "Ludwig",
    "Pokimane",
    "xQc",
    "Sodapoppin",
    "MoistCr1TiKaL"
]
